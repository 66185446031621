/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Swiss Ocean Tech Innovation Guardians Club"), "\n", React.createElement(_components.p, null, "Swiss Ocean Tech has recently launched an exclusive club called the Innovation Guardians for superyacht captains."), "\n", React.createElement(_components.h2, null, "The Innovation Guardians Club"), "\n", React.createElement(_components.p, null, "The club is comprised of 100 visionary captains who exemplify leadership, embrace innovation, and have a profound love for the ocean. The main objective of the club is to act as product testers for Swiss Ocean Tech's anchoring system, AnchorGuardian."), "\n", React.createElement(_components.p, null, "The Innovation Guardians club is an opportunity for superyacht captains to be at the forefront of maritime safety and technology. By participating in the club, captains will have the chance to test and provide anonymous data on the performance of AnchorGuardian. In return, they will receive exclusive benefits, including special discounts on the product."), "\n", React.createElement(_components.p, null, "Swiss Ocean Tech aims to revolutionize the industry by developing innovative solutions for safe anchoring. The company is committed to working closely with the superyacht community and leveraging the expertise of the Innovation Guardians to enhance the safety and efficiency of anchoring systems."), "\n", React.createElement(_components.p, null, "Reference:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yachting-pages.com/articles/swiss-ocean-tech-club-news.html"
  }, "Yachting Pages - Swiss Ocean Tech Launches Exclusive Club for Captains")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
